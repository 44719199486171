.under-construction {
  border: 5px solid black;
  border-radius: 10px;
  color: black;
  background-color: yellow;
  padding: 20px;
  margin: 20px;
}

.warning {
  background-color: black;
  color: yellow;
  border-radius: 5px;
  font-size: 60px;
}